<template>
  <div>
    <Breadcrumbs main="" title="Users" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
                <b-row class="">
                <b-col class="col-md-6 col-12 mb-3">
                  <b-input-group class="col-12 col-md-8">
                    <b-form-input
                      v-model="filter"
                      placeholder="Type to Search"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-col>

                <b-col class="text-right col-md-6 col-12">
                  <b-form-group
                    label-cols="9"
                    label="Per page"
                    class="mb-0"
                  >
                    <b-form-select
                    class="col-md-10"
                      v-model="perPage"
                      :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="col-md-12" v-if="loading">
                <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 
              <div v-else class="table-responsive datatable-vue">
                <b-table
                  show-empty
                  stacked="md"
                  :items="items"
                  :filter="filter"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @filtered="onFiltered"
                  :fields="tablefields"
                >
                <template #table-caption>User List.</template>

                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(email)="data">
                  <span class="">{{ data.item.email }}</span>
                </template>

                <template #cell(country)="data">
                  <multiselect
                    :options="countryOpt"
                    label="name"
                    track-by="id"
                    v-model="data.item.countryArray"
                    placeholder="Select Country"
                    :multiple="true"
                    :max="5"
                    @input="assignCountry(data.item)"
                  >
                  </multiselect>
                </template>

                <template #cell(role)="data">
                  <span class="">
                    <b-form-select
                      class="col-md-10"
                      v-model="data.item.role"
                      :options="role_opt"
                      @change="roleUpdate(data.item)"
                    ></b-form-select>
                  </span>
                </template>
                <template #cell(isactive)="data">
                  <div class="media-body icon-state switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=data.item.isactive @change="statusUpdate(data.item)">
                        <span class="switch-state" :class="data.item.isactive?'bg-success':'bg-danger'"></span>
                        </label>
                    </div>
                </template>
                </b-table>
              </div>

              <b-col md="6" class="mt-3 p-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  class="my-0"
                ></b-pagination>
              </b-col>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import UserController from "../../../services/UserService"
import NetworkTypeService from '../../../services/NetworkTypeService'
import Multiselect from "vue-multiselect";
import moment from "moment";
export default {
    name:'Users',
    components:{
        Multiselect
    },
    data(){
        return{
            allocatePlan:false,
            input:{plan_id:null},
            plan_opt:[{"value":null,"text":'select plan to allocate'}],
            plans:[],
            items:[],
            totalRows:0,
            filter:null,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 50],
            loading:false,
            tablefields: [ 'index',
                          { key: "username", label: "User Name", sortable: true },
                          { key: "email", label: "Email", sortable: true },
                          { key: "role", label: "Role", sortable: true },
                          { key: "country", label: "Country", sortable: true },
                          { key: "isactive", label: "Is Active", sortable: false },
                        ],
            role_opt:{user:'User', moderator:'Moderator'},
            userinfo :[],
            user_data:[],
            countryOpt:[],
        }
    },
    mounted(){
        this.userinfo = JSON.parse(window.localStorage.getItem('userinfo'))
        this.getUsers()
        this.getCountry();
    },
    methods: {
        async getUsers(){
            this.loading = true;
            this.items = []
            let response = await UserController.allUsersList()
            if (response.result) {
                this.items = response.message.filter(t=>{
                    if(t.role != 'manager'){
                        return true;
                    }
                });
                this.totalRows = this.items.length;
            }
            else{
              this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
            }
            this.loading = false;
        },
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async statusUpdate(data){
        if(data.countryArray.length >0){
        let status = true
        if(data.isactive == true){
            status = false
        }
        if (data.isactive == false) {
          data.isactive = true;
        } else {
          data.isactive = false;
        }
        var payload = {"useridTobeChange": data._id, "tobeModify": {isactive: status}}
        let response = await UserController.updateUser(payload)
        if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
            // this.getUsers()
        }
      }else{
        this.$toasted.show('Please assign at least one country ', {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
      }
    },
     async roleUpdate(data){
      if(confirm('Are you sure to change role?')){
        var payload = {"useridTobeChange": data._id, "tobeModify": {role: data.role}}
        let response = await UserController.updateUser(payload)
        if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
        }
      }
      else{
        data.role = data.role == 'user' ? 'moderator' : 'user'
        // console.log(data.role)
      }
    },
    async assignCountry(data){
      var payload = {"useridTobeChange": data._id, "tobeModify": {countryArray: data.countryArray}}
      // if(data.countryArray.length < 1){
      //   var payload = {"useridTobeChange": data._id, "tobeModify": {isactive: false,countryArray: data.countryArray}}
      // }
      let response = await UserController.updateUser(payload)
        if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
        }
    },
    changeDateFormate(d) {
      return moment.utc(d).format("D/M/YYYY, HH A");
    },
    async getCountry(){
      let response = await NetworkTypeService.getCountry();
      this.countryOpt = [];
      response.data.forEach(element => {
        this.countryOpt.push({id:element.alpha_3,name:element.name})
      });
    },
    }
}
</script>

<style>

.table > tbody > tr > td {
  overflow: visible !important;
}
</style>